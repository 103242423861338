<template>
  <div style="width: 100%; height: 100%">
    <div class="title">
      <div class="login">登录</div>
      <div class="register" @click="$router.push({ path: 'register' })">
        注册账号
      </div>
    </div>
    <div class="logo">
      <img
        class="img"
        src="https://t.yhaocang.com/upload/sf_static/img/login/logo.png"
      />
    </div>
    <div v-show="show" class="formBtn">
      <div class="iphoneNumber">
        <!-- <image src="../../static/img/login/user.png" mode=""></image> -->
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="user"
          center
          clearable
          placeholder="请输入手机号码、登录ID"
          label-width="250px"
        >
          <template #left-icon>
            <img
              style="width: 22px; height: 22px"
              src="https://t.yhaocang.com/upload/sf_static/img/login/user.png"
              alt=""
            />
          </template>
          <template #right-icon>
            <van-icon name="arrow" size="18px" />
          </template>
        </van-field>
      </div>
      <div class="code">
        <van-field
          ref="abc"
          v-show="pswShow"
          type="password"
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="psw"
          center
          clearable
          placeholder="请输入登录密码"
          label-width="250px"
        >
          <template #left-icon>
            <!-- <van-icon name="contact" color="#EDB95C" size="24px"/> -->
            <img
              style="width: 22px; height: 22px"
              src="https://t.yhaocang.com/upload/sf_static/img/login/psw.png"
              alt=""
            />
          </template>
          <template #right-icon>
            <van-icon @click="pswShowClick()" name="closed-eye" size="18px" />
          </template>
        </van-field>
        <van-field
          v-show="!pswShow"
          type="txt"
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="psw"
          center
          clearable
          placeholder="请输入登录密码"
          label-width="250px"
        >
          <template #left-icon>
            <!-- <van-icon name="contact" color="#EDB95C" size="24px"/> -->
            <img
              style="width: 22px; height: 22px"
              src="https://t.yhaocang.com/upload/sf_static/img/login/psw.png"
              alt=""
            />
          </template>
          <template #right-icon>
            <!-- <div class="VerificationCode">获取验证码</div> -->
            <van-icon @click="pswShowClick()" name="eye-o" size="18px" />
          </template>
        </van-field>
      </div>
      <div class="agreement">
        <van-checkbox v-model="checked" icon-size="18px">
          <span class="agreementA">我已阅读并同意</span>
          <span class="agreementB">《服务协议》</span>
          <span class="agreementA">和</span>
          <span class="agreementB">《隐私政策》</span>
        </van-checkbox>
      </div>
      <div class="submit" @click="submit">确定</div>
      <div class="footer">
        <div @click="$router.push({ path: '/FindPsw' })">忘记密码?</div>
        <div @click="formShow">验证码登录</div>
      </div>
    </div>

    <div v-show="!show" class="formBtnB">
      <div class="iphoneNumber">
        <!-- <image src="../../static/img/login/user.png" mode=""></image> -->
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="phone"
          center
          clearable
          placeholder="请输入手机号码"
          label-width="250px"
        >
          <template #left-icon>
            <img
              style="width: 22px; height: 22px"
              src="https://t.yhaocang.com/upload/sf_static/img/login/phone.png"
              alt=""
            />
          </template>
        </van-field>
      </div>
      <div class="code">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="code"
          center
          clearable
          placeholder="请输入短信验证码"
          label-width="250px"
          ref="loginFromA"
        >
          <template #left-icon>
            <img
              style="width: 22px; height: 22px"
              src="https://t.yhaocang.com/upload/sf_static/img/login/psw.png"
              alt=""
            />
          </template>
          <template #right-icon>
            <div v-show="codeShow" class="VerificationCode" @click="LoginFrom">
              获取验证码
            </div>
            <div v-show="!codeShow" class="VerificationCode">
              {{ yzmTitle }}
            </div>
          </template>
        </van-field>
      </div>
      <div class="agreement">
        <van-checkbox v-model="checked" icon-size="18px" @change="change">
          <span class="agreementA">我已阅读并同意</span>
          <span class="agreementB">《服务协议》</span>
          <span class="agreementA">和</span>
          <span class="agreementB">《隐私政策》</span>
        </van-checkbox>
      </div>
      <div class="submit" @click="loginYan">确定</div>
      <div class="footer">
        <div @click="$router.push({ path: '/FindPsw' })">忘记密码?</div>
        <div @click="formShowB">手机号登录</div>
      </div>
    </div>
    <div class="wx-login" v-if="$store.state.isWeiXin" @click="WxLogin">
      <div class="wx-icon"></div>
      <div class="wx-div">微信登录</div>
    </div>
  </div>
</template>

<script>
import { Login, wxLogin, LoginFromList, getCodeList } from "@/api/login";
export default {
  data() {
    return {
      checked: localStorage.getItem("check")
        ? localStorage.getItem("check")
        : false,
      pswShow: true,
      user: "",
      psw: "",
      phone: "",
      code: "",
      show: true,
      yzmTitle: "",
      codeShow: true
    };
  },
  created() {
    this.$nextTick(() => {
      console.log(this.$refs.abc, "延迟加载可以获取到");
    });
    if (this.$route.query.code) {
      this.WxLogin();
    }
  },
  watch: {
    user: function(newVal) {
      if (newVal.length === 0) {
        this.psw = "";
      }
    },
    immediate: true
  },
  methods: {
    WxLogin() {
      wxLogin({ returnUrl: location.href, code: this.$route.query.code }).then(
        res => {
          console.log(res);
          if (res.data.code == 302) {
            window.location.href = res.data.data.url;
          }
          if (res.data.code == 200) {
            localStorage.setItem("access_id", res.data.data.token);
            localStorage.setItem("identity", res.data.data.identity);
            localStorage.setItem("rid", res.data.data.user_id);
            res.data.data.identity == 1
              ? this.$router.replace("/channel")
              : this.$router.replace("/myCard");
          }
          if (res.data.code == 201) {
            this.$router.push({
              path: "/bindAccount",
              query: {
                openid: res.data.data.openid
              }
            });
          }
        }
      );
    },
    formShow() {
      this.show = false;
    },
    formShowB() {
      this.show = true;
    },
    LoginFrom() {
      // alert("11");
      let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!phonereg.test(this.phone)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.phone) {
        this.$toast("请输入手机号码");
        return;
      }
      let params = {
        mobile: this.phone
      };
      getCodeList(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.codeShow = true;
            } else {
              this.codeShow = false;
              this.yzmTitle = time + "秒后重试";
              time--;
            }
          }, 1000);
          this.$toast("验证码获取成功");
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    pswShowClick() {
      this.pswShow = !this.pswShow;
    },
    // 主页登录
    submit() {
      if (!this.user || !this.psw) {
        this.$toast("请输入手机号、登录ID或者密码");
        return;
      }
      if (this.checked == false) {
        this.$toast("请勾选同意《服务协议》和《隐私政策》");
        return;
      }
      let params = {
        mobile: this.user,
        password: this.psw
      };
      Login(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          localStorage.setItem("access_id", res.data.data.access_id);
          localStorage.setItem("identity", res.data.data.identity);
          localStorage.setItem("rid", res.data.data.user_id);
          res.data.data.identity == 1
            ? this.$router.push("/channel")
            : this.$router.push("/myCard");
        } else {
          this.$toast.fail(res.data.message);
        }
      });
    },
    // 短信验证登录
    loginYan() {
      // 登录预校验
      if (!this.phone || !this.code) {
        this.$toast("请输入手机号或者短信验证码！");
        return;
      }
      if (this.checked == false) {
        this.$toast("请勾选同意《服务协议》和《隐私政策》");
        return;
      }
      let params = {
        mobile: this.phone,
        code: this.code
      };
      // 获取验证码请求接口
      LoginFromList(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          localStorage.setItem("access_id", res.data.data.token);
          localStorage.setItem("identity", res.data.data.identity);
          localStorage.setItem("rid", res.data.data.user_id);
          res.data.data.identity == 1
            ? this.$router.push("/channel")
            : this.$router.push("/myCard");
        } else {
          this.$toast.fail(res.data.message);
        }
      });
    },
    change(e) {
      console.log(e);
      localStorage.setItem("check", e);
    }
  }
};
</script>

<style lang="scss" scoped>
.wx-div {
  width: 300px;
}
.title {
  width: 100%;
  height: 31px;
  font-size: 33px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  padding-top: 88px;
  text-align: center;
  // .login {
  //   text-align: center;
  // }
  .register {
    position: absolute;
    right: 63px;
    top: 95px;

    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.logo {
  text-align: center;
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  .img {
    width: 182px;
    height: 182px;
  }
}
.formBtn {
  padding-top: 67px;
  margin: 0 65px 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .iphoneNumber {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    .inputPhoneNumber {
      height: 100%;
      margin-left: 40px;
    }
  }
  .code {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    margin-top: 52px;
    .inputCode {
      height: 100%;
      margin-left: 40px;
    }
    .VerificationCode {
      width: 174px;
      height: 51px;
      border: 1px solid #d2d2d2;
      border-radius: 26px;

      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
  }
  .agreement {
    display: flex;
    margin-top: 22px;
    .agreementA {
      font-size: 21px;
      font-weight: 400;
      color: #999999;
    }
    .agreementB {
      font-size: 21px;
      font-weight: 400;
      color: #ebab3f;
    }
  }
  .submit {
    width: 100%;
    height: 90px;
    background: linear-gradient(-30deg, #323234, #464646);
    border-radius: 45px;
    line-height: 90px;
    text-align: center;
    color: #ffffff;
    margin-top: 52px;

    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
  .footer {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    div {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
}
.formBtnB {
  padding-top: 67px;
  margin: 0 65px 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .iphoneNumber {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    .inputPhoneNumber {
      height: 100%;
      margin-left: 40px;
    }
  }
  .code {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    margin-top: 52px;
    .inputCode {
      height: 100%;
      margin-left: 40px;
    }
    .VerificationCode {
      width: 174px;
      height: 51px;
      border: 1px solid #d2d2d2;
      border-radius: 26px;

      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
  }
  .agreement {
    display: flex;
    margin-top: 22px;
    .agreementA {
      font-size: 21px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
    .agreementB {
      font-size: 21px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ebab3f;
    }
  }
  .submit {
    width: 622px;
    height: 90px;
    background: linear-gradient(-30deg, #323234, #464646);
    border-radius: 45px;
    line-height: 90px;
    text-align: center;
    color: #ffffff;
    margin-top: 52px;
    opacity: 0.5;

    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
  .footer {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    div {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
}
.wx-login {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 100px;
  .wx-icon {
    width: 100px;
    height: 100px;
    background: url("https://t.yhaocang.com/upload/sf_static/img/login/wxlogin-icon2.png");
    display: block;
    background-size: 100%;
    margin: auto;
  }
  p {
    line-height: 2;
    color: #666;
    text-align: center;
  }
}
</style>
